import { render, staticRenderFns } from "./moderate-response.vue?vue&type=template&id=032fa1d8&scoped=true&"
import script from "./moderate-response.vue?vue&type=script&lang=js&"
export * from "./moderate-response.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "032fa1d8",
  null
  
)

export default component.exports