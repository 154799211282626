<template>
  <b-modal v-model="show" hide-footer hide-header centered size="lg">
    <b-overlay :show="isLoading">
      <h3 class="mt-1 mb-2">
        Відповідь на звернення #{{ editable_event.ticket }}
        <small class="date text-muted" v-if="editable_event.created_at">
          {{ editable_event.created_at.seconds | moment("calendar") }}
        </small>
      </h3>
      <b-form-textarea rows="8" v-model="editable_event.response_text">
      </b-form-textarea>
      <div class="checkboxes pt-1">
        <label for="moderate-make-public" class="d-flex align-items-center">
          <b-form-checkbox
            id="moderate-make-public"
            name="moderate-make-public"
            v-model="editable_event.make_public"
          ></b-form-checkbox>
          Зробити відповідь публічною
        </label>
        <div class="confirm mr-auto" v-if="editable_event.make_public">
          <label
            for="moderate-make-author-public"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              id="moderate-make-author-public"
              name="moderate-make-author-public"
              v-model="editable_event.make_author_public"
            ></b-form-checkbox>
            Показати автора запиту публічно
          </label>
        </div>
      </div>
      <div class="controls pt-2">
        <b-button @click="approve" variant="primary" class="w-100 mb-1">
          Зберегти та затвердити
        </b-button>
        <b-button @click="show = false" variant="outline-primary" class="w-100"
          >Закрити</b-button
        >
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      show: false,
      editable_event: {},
      original_text: "",
    };
  },
  watch: {
    event: {
      deep: true,
      handler: function (val, oldVal) {
        this.editable_event = { ...this.event };
        this.original_text = this.editable_event.response_text;
      },
    },
  },
  methods: {
    async approve() {
      this.isLoading = true;
      let newData = {
        approved: true,
        response_text: this.editable_event.response_text,
        make_public: this.editable_event.make_public,
        make_author_public: this.editable_event.make_author_public,
      };
      if (this.editable_event.response_text != this.original_text) {
        newData.is_edited = true;
      }
      await this.$db.update("tickets_events", this.editable_event.uid, newData);
      this.show = false;
      this.$emit("moderated");
      this.isLoading = false;
    },
  },
  mounted() {
    this.editable_event = { ...this.event };
  },
};
</script>

<style lang="scss" scoped></style>
