<template>
  <div>
    <b-overlay :show="isLoading">
      <moderation-item
        v-for="event in events"
        :key="event.uid"
        :event="event"
        @moderate="startModeration(event)"
      />
    </b-overlay>
    <b-alert
      variant="warning"
      class="p-2"
      :show="!isLoading && dataLoaded && !events.length"
    >
      Немає подій для модерації
    </b-alert>
    <moderate-modal
      @moderated="getUnmoderatedEvents"
      ref="moderateModal"
      :event="moderatingEvent"
    />
  </div>
</template>

<script>
import moderateModal from "@/components/modals/moderate-response.vue";
import moderationItem from "@/components/moderation-list-item.vue";
export default {
  components: {
    moderationItem,
    moderateModal,
  },
  data() {
    return {
      isLoading: false,
      events: [],
      moderatingEvent: {},
      dataLoaded: false,
    };
  },
  methods: {
    startModeration(event) {
      console.log(event);
      this.moderatingEvent = event;
      this.$refs.moderateModal.show = true;
    },
    async getUnmoderatedEvents() {
      this.isLoading = true;
      let args = {
        collectionName: "tickets_events",
        where: ["approved", "==", false],
        order: ["created_at", "desc"],
      };
      let events = await this.$db.getCollection(args);
      this.events = events.docs.filter((el) => {
        return el.next_status != 3;
      });
      this.isLoading = false;
      this.dataLoaded = true;
    },
  },
  mounted() {
    this.getUnmoderatedEvents();
  },
};
</script>

<style lang="scss" scoped></style>
