<template>
  <b-card>
    <div class="d-flex mb-1">
      <router-link :to="'/tickets/t/' + event.ticket">
        <h4>Відповідь на звернення #{{ event.ticket }}</h4>
      </router-link>
      <div class="date text-muted ml-auto" v-if="event.created_at">
        {{ event.created_at.seconds | moment("calendar") }}
      </div>
    </div>
    <p>
      <em>{{ event.response_text }}</em>
    </p>
    <div class="d-flex align-items-center pt-1">
      <div class="d-flex align-items-center">
        <status-badge :status="event.prev_status" />
        <span class="mx-1"> → </span>
        <status-badge :status="event.next_status" />
      </div>
      <div class="controls ml-auto">
        <b-button
          target="_blank"
          :to="'/tickets/t/' + event.ticket"
          variant="outline-primary"
          class="mr-1"
        >
          Деталі звернення
        </b-button>
        <b-button @click="moderate" variant="primary"> Модерувати </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import statusBadge from "@/components/ticket-status-badge";
export default {
  components: {
    statusBadge,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    moderate() {
      const event = { ...this.event };
      this.$emit("moderate", event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
